.title-container {
  display: flex;
  justify-content: space-between;
  padding-right: 10rem;
  padding-left: 8rem;
  align-items: flex-end;
  margin-top: 4rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.status-container {
  margin: 1rem 10rem 0rem 10rem;
  padding: 1rem;
  border: 1px solid gray;
}

.status-title {
  display: flex;
}
