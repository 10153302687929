.app-card{
  display: flex;
  /* width: 300px; */
  flex-direction: column;
  height: 300px;
  margin: 30px;
  align-items: flex-start;
  /* justify-content: center; */
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  border-radius: 10px;
  min-width: 350px;
}

.app-card-container{
  display: flex;
  width: 100%;
  height: 55%;
  align-items: center;
  justify-content: center;
}

.app-card:hover{
  width: 310px;
  height: 300px;
  margin: 25px;
}

.app-card-icon{
  color: #2185d0;
}
