.assistant-output-node {
  display: flex;
  width: 192px;
  height: 110%;
  border: 3px solid rgba(0, 60, 255, 0.2);
  padding: 1px;
  margin: 0px;
  border-radius: 5px;
  background: white;
  overflow: auto;
  cursor: auto;
}

.assistant-output-node textarea{
  width: 98%;
  font-size: 10px;
  height: 100px;
  overflow: auto;
  columns: 10;
}

.assistant-output-node button{
  background-color: rgba(7, 80, 163, 0.603);
  border-radius: 3px;
  border: 0px;
  color: white;
  font-size: 12px;
}

.assistant-output-node textarea::-webkit-scrollbar {
  width: 5px;
}

.assistant-output-node textarea::-webkit-scrollbar-thumb:hover {
  width: 15px;
}

.assistant-output-node textarea::-webkit-scrollbar-track {
  background: white;
}

.assistant-output-node textarea::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
}


.assistant-output-node::-webkit-scrollbar {
  width: 5px;
}

.assistant-output-node::-webkit-scrollbar-track {
  background: white;
}

.assistant-output-node::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
}
