.account-card{
  display: flex;
  /* width: 300px; */
  height: 150px;
  margin: 10px 30px 10px 30px;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  border-radius: 10px;
  min-width: 300px;
  /* max-width: 100%; */
}


.account-card:hover{
  width: 310px;
  height: 160px;
  margin: 5px 25px 5px 25px;
}