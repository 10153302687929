.modal-overlay {

  background: '#F4F6FA';
  position: fixed;
  top: 50px;
  right: 200px;
  left: 200px;
  bottom: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
