.form-input {
  display: block;
  max-width: 50rem;
  width: 50rem;
  margin: 5px 5px 0 5px;
}

input:invalid {
  background-image: url(https://cdn0.iconfinder.com/data/icons/shift-interfaces/32/Error-512.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 48rem 5px;
}

input:valid {
  background-image: url(https://imagepng.org/wp-content/uploads/2019/12/check-icone-1-scaled.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 48rem 5px;
}

textarea {
  border: 2px solid black;
}

textarea:invalid {
  background-image: url(https://cdn0.iconfinder.com/data/icons/shift-interfaces/32/Error-512.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 48rem 5px;
}

textarea:valid {
  background-image: url(https://imagepng.org/wp-content/uploads/2019/12/check-icone-1-scaled.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 48rem 15px;
}
