.app-cards-container{
  display: flex;
  /* height: 100%; */
  flex-wrap: wrap;
  margin: 50px;
  justify-content: flex-start;
  padding: 0px;
}

.apps-icon{
  font-size: 30px;
  margin: 50px 0px 0px 50px;
}
