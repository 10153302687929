.intent-node {
  display: flex;
  width: 200px;
  /* height: 50px; */
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: rgba(0, 60, 255, 0.2);
  justify-content: center;
}

.intent-node button{
  background-color: white;
  border-radius: 3px;
  border: 0px;
  margin: 10px;
  color: rgb(0, 0, 0);
  font-size: 100%;
}
